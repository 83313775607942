import React, { useEffect, useRef } from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import parse from "html-react-parser"
import { Row, Col, Button } from "react-bootstrap"
// import Seo from "../components/seo"
import Layout from "../components/layout"
// import SocialShare from "../components/socialShare"
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"
import "../../static/reviewer/assets/css/reviewer-public.css"
import "../css/easy-accordion.scss"

const Content = ({ data }) => {
  const articleBodyRef = useRef(null)

  useEffect(() => {
    if (articleBodyRef.current) {
      if (typeof window !== undefined) {
        const spEasyAccordion = document
          .getElementById("articleBody")
          .querySelector(".sp-easy-accordion")
        if (spEasyAccordion) {
          ;[...spEasyAccordion.querySelectorAll(".ea-card")].forEach(
            cardItem => {
              const cardHeader = cardItem.querySelector(".ea-header")

              cardHeader.querySelector("a").removeAttribute("href")

              cardHeader.addEventListener("click", e => {
                if (cardItem.classList.contains("show")) {
                  cardItem.classList.remove("show")
                  cardHeader.querySelector("i").classList.remove("fa-")
                  cardHeader.querySelector("i").classList.add("fa-plus")
                } else {
                  cardItem.classList.add("show")
                  cardHeader.querySelector("i").classList.remove("fa-plus")
                  cardHeader.querySelector("i").classList.add("fa-minus")
                }
              })
            }
          )
        }
      }
    }
  }, [articleBodyRef])

  return (
    <div ref={articleBodyRef} id="articleBody">
      {parse(data)}
    </div>
  )
}

const reviews = ({ data }) => {
  const featuredImage = {
    fluid: data.post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: data.post.featuredImage?.node?.alt || ``,
  }

  return (
    <Layout>
      <Row className="post">
        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header className="mb-5">
            <Col xs={12}>
              <h3
                className="text-center pl-3 pr-3 post-title font-weight-bolder"
                itemProp="headline"
              >
                {parse(data?.post?.title.toUpperCase())}
              </h3>
              <small className="text-center font-small d-block">
                {data?.post.date}
              </small>
            </Col>
          </header>

          <Col xs={12}>
            {!!data?.post.content && (
              <section itemProp="articleBody">
                <div className="w-full">
                  {featuredImage?.fluid && (
                    <Image
                      fluid={featuredImage.fluid}
                      alt={featuredImage.alt}
                      style={{ marginBottom: 50 }}
                    />
                  )}
                </div>

                <Content data={data.post.content} />
              </section>
            )}
          </Col>
        </article>
        <Row className="mb-5 w-100 m-auto">
          <Col xs={6}>
            {!!data.previous?.title && (
              <Link to={`${data.previous.uri}`} className="d-flex flex-column">
                <Button variant="primary" className="navigation">
                  Previous
                </Button>
                <h6 className="d-block mt-3 text-left font-bold">
                  {data.previous.title}
                </h6>
              </Link>
            )}
          </Col>
          <Col xs={6}>
            {!!data?.next?.title && (
              <Link to={`${data.next.uri}`} className="d-flex flex-column">
                <Button variant="primary" className="navigation">
                  Next
                </Button>
                <h6 className="d-block mt-3 text-right font-bold">
                  {data.next.title}
                </h6>
              </Link>
            )}
          </Col>
          <hr />
        </Row>

        {/* <SocialShare title={data?.post?.title} /> */}
      </Row>
    </Layout>
  )
}

export default reviews

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      review

      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
